import React from 'react';
import Page from "./page.js"
import { Link } from "gatsby"
import { Icon, FirstP, Project } from "./../components/helpers.js"
import { TiHome, TiCalendar } from "react-icons/ti";
import { FiArrowRight, FiBox, FiShoppingCart, FiActivity, FiEye } from "react-icons/fi";
import { RiCheckboxCircleLine } from "react-icons/ri";
import Img from "gatsby-image"

import { Helmet } from 'react-helmet'
import styled from 'styled-components'

var slugify = require('slugify')

const ProjectTable = styled.table`

    text-align: left;
    line-height: 1.9em;
    width:100%;
    margin-bottom:10px;

    td {
        vertical-align: top;
    }

    td:first-child {
        width:1.3em;
    }

`;

const Log = styled.div`
    &:not(:last-child) {        
        border-bottom: 1px solid #ddd;
    }    

    p {
        margin-top:0px;
    }
`;

const Title = styled.div`
    display:flex;
    margin-top:18px;
    justify-content:space-between;
    flex-wrap:wrap;

    p, h4 {
        margin-bottom:0em; 
    }    

    h4 {
        margin-top:0.3em;
    }

    @media screen and (max-width: 420px) {
        p {
            width:100%;
        }
        p:last-of-type {
            margin-bottom:0.4em;
        }
    }
`;

export default (props) => {
        
        const proj = props.pageContext.project;
        const projList = props.pageContext.projects;

        const hero = <div><h1>{proj.name}</h1>
        </div>;        

        var content = <><FirstP>{proj.description}</FirstP>        
        <h2>Build Logs</h2></>;

        for (var i=proj.logs.length-1; i>=0; i--)
        {
            var date = new Date(proj.logs[i].date).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' });

            content = <>{content}<Log>
            <Title><h4><Link to={"/" + slugify(proj.name, {lower: true}) + '/logs/' + slugify(proj.logs[i].title, {lower: true}) + '/'}>{proj.logs[i].title}</Link></h4>
            <p><Icon><TiCalendar /></Icon> {date}</p></Title>
            <p>{proj.logs[i].description}</p></Log>
            </>
        } 

        var items;
        for (i=projList.length-1; i>=0; i--)
        {            
            if (projList[i].name === proj.name)
            {
                items = <>{items}<tr><td><Icon><FiArrowRight /></Icon></td><td>{proj.name}</td></tr></>
            } else {
                items = <>{items}<tr><td><Icon><FiBox /></Icon></td><td>
                            <Link to={'/' + slugify(projList[i].name, {lower: true}) + '/'}>
                                {projList[i].name}
                            </Link></td></tr>
                        </>
            }
        } 

        var color;
        if (proj.status==='Finished')
            color = 'finished'
        else if (proj.status === 'In research')
            color = 'research'
        else if (proj.status === 'For sale')
            color = 'sale'
        else 
            color = 'ongoing'


        return (
        <><Helmet>
                <title>{proj.name} - Maakbaas</title>
                <meta name="description" content={proj.description} />
        </Helmet>
        <Page 
                    sidebar={<>
                        <Project>
                        <h3>{proj.name}</h3>
                        <h4><Icon><FiBox /></Icon> <span className="projectID">Project {proj.id}</span> <span className={"projectTag " + color}>
                                {proj.status === 'For sale' ? <Link to={proj.productUrl}><Icon><FiShoppingCart /></Icon> Buy this</Link> : ''}
                                {proj.status === 'Finished' ? <><Icon><RiCheckboxCircleLine /></Icon> Finished</> : ''}
                                {proj.status === 'Ongoing' ? <><Icon><FiActivity /></Icon> Ongoing</> : ''}
                                {proj.status === 'In research' ? <><Icon><FiEye /></Icon> In research</> : ''}</span></h4>
                        
                        <Img fadeIn={false} fluid={proj.imgData} style={{ borderRadius: '4px', marginBottom: '2em' }} alt="Project image" />
                        
                        </Project>

                        <h3>All Projects</h3>

                        <ProjectTable><tbody>{items}</tbody></ProjectTable>
                    </>}
                    sidebar2={<p><Icon><TiHome /></Icon> <Link to="/">Back to home</Link></p>}
                    content={content}
                    heroJSX={hero}
                    color1={proj.color1}
                    color2={proj.color2}
        /></>);    
}